<template>
    <v-app>
        <v-row no-gutters class="fill-height">
            <v-col cols="12" lg="6" class="d-flex align-center justify-center">
                <v-sheet
                    width="550"
                    class="poppins d-flex flex-column align-center justify-center"
                >
                    <v-card height="450">
                        <v-card-title>
                            <v-container class="container-main">
                                <div class="d-flex mb-8 center-content">
                                    <v-img
                                        id="horizontal_logo"
                                        max-width="80"
                                        :src="
                                            customization.hasOwnProperty(
                                                'login_logo',
                                            )
                                                ? customization.login_logo
                                                : require('../assets/images/logo.png')
                                        "
                                        v-on:error="
                                            delete customization.login_logo
                                        "
                                        contain
                                        transition="scale-transition"
                                    />
                                    <span
                                        style="font-size: 20px; color: #1e3988"
                                        >Philippine Society of General
                                        Surgeons</span
                                    >
                                </div>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <h2 class="black-font black--text">
                                Forgot Password
                            </h2>
                            <span
                                >Enter your email address below and we’ll send
                                you an instruction to reset your password.</span
                            >
                            <alert
                                :show="msg.show"
                                :text="msg.text"
                                :type="msg.type"
                            />
                            <div class="my-5">
                                <FormTextFieldRequired
                                    :label="'EMAIL ADDRESS *'"
                                    :value.sync="email"
                                    :error="errors.email"
                                />
                                <v-btn
                                    @click="submit"
                                    large
                                    block
                                    color="primary"
                                    class="border mt-3 primary-font"
                                    :loading="loading"
                                    >Reset Password</v-btn
                                >
                            </div>
                        </v-card-text>
                    </v-card>
                </v-sheet>
            </v-col>
            <v-col cols="12" lg="6" class="hidden-md-and-down">
                <div style="width: 100%; height: 100vh; overflow: hidden">
                    <v-img
                        src="../assets/images/login-banner.png"
                        style="width: 100%; height: 100%; object-fit: contain"
                    >
                    </v-img>
                </div>
            </v-col>
        </v-row>
    </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AuthFooter from "../components/AuthFooter.vue";
export default {
    components: {
        AuthFooter,
    },
    data: () => ({
        email: "",
        loading: false,
        asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
        msg: {
            show: false,
            text: "",
            type: "",
        },
    }),
    methods: {
        ...mapActions(["forgotPasswordAction"]),

        submit() {
            this.loading = true;
            this.forgotPasswordAction({
                email: this.email,
                url: window.location.origin,
            })
                .then((res) => {
                    this.msg = {
                        show: true,
                        text: res,
                        type: "success",
                    };
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },

    computed: {
        ...mapState({
            customization: (state) => state.customization,
            errors: (state) => state.errors,
        }),
    },
};
</script>
